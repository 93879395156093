import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {withNamespaces} from "react-i18next";
import {getLoggedInUser, logout} from "../../../services/auth";
import {useHistory, withRouter} from "react-router-dom";
import SocketIO from "../../../services/socketIO";
import LinkRegistrationClient from "../../VerticalLayout/components/linkRegistrationClient";

const ProfileMenu = () => {

    const history = useHistory();
    const [menu, setMenu] = useState(false);
    const [sendLinkToClientVisible, setSendLinkToClientVisible] = useState(false);

    let user = getLoggedInUser();
    let username = user.firstName + ' ' + user.lastName;
    let userCircle = user.firstName.charAt(0) + user.lastName.charAt(0);

    const toggle = () => {
        setMenu(!menu);
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
                <DropdownToggle tag="button" className="btn header-item waves-effect"
                                id="page-header-user-dropdown">
                    <div className="rounded-circle header-profile-user mr-1" alt="Profile Circle">{userCircle}</div>
                    <span className="d-none d-xl-inline-block ml-1 text-transform">{username}</span>
                    <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => history.push('/profile')}><i
                        className="ri-user-line align-middle mr-1"></i> פרופיל
                    </DropdownItem>
                    <DropdownItem href="#" onClick={() => setSendLinkToClientVisible(true)}><i
                        className="mdi mdi-link-variant align-middle mr-1"></i>קישור הרשמה
                    </DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem
                        className="text-danger"
                        onClick={() => {
                            SocketIO.disconnect();
                            logout()
                            history.push('/login');
                        }}><i
                        className="ri-shut-down-line align-middle mr-1 text-danger"></i> התנתק
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>


            <LinkRegistrationClient
                visible={sendLinkToClientVisible}
                onClose={() => setSendLinkToClientVisible(false)}
            />
        </React.Fragment>
    );
}

export default ProfileMenu;
