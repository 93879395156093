const folders = {
  api: "/api"
}
const baseService = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  options: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: undefined
    }
  },
  endpoints: {
    banks: {
      getAll: folders.api + "/banks",
      branchs: folders.api + "/bank/branchs?:bankCode",
    },
    manufacturers: {
      getAll: folders.api + "/manufacturers?:current&:pageSize&:manufacturer_name&:business_number&:product_name&:deductions_portfolio",
      manufacturersOptions: folders.api + "/manufacturers/options",
      create: folders.api + "/manufacturers/create",
      update: folders.api + "/manufacturers/update"
    },
    manufacturerProduct: {
      create: folders.api + "/manufacturer/product/create",
      update: folders.api + "/manufacturer/product/update"
    },
    companies: {
      companies: folders.api + "/companies?:current&:pageSize",
      companiesList: folders.api + "/companies/list",
      create: folders.api + "/companies/create",
      update: folders.api + "/companies/update"
    },
    user: {
      login: folders.api + "/users/login",
      getAllUsers: folders.api + "/users?:current&:pageSize",
      getUserDetails: folders.api + "/user/details",
      updateUserDetails: folders.api + "/user/details/update",
      uploadLogo: folders.api + "/user/logo/upload",
      getAgentByCompany: folders.api + "/users/agents?:current&:pageSize",
      getUsersType: folders.api + "/users/type?:type",
      create: folders.api + "/users/create",
      update: folders.api + "/users/update",
      register: folders.api + "/users/register",
      registrationLinkClient: folders.api + "/user/send/link",
      getUser: folders.api + "/user?:userId",
      getUserType: folders.api + "/user/type?:userId",
      getPensionInformationFromMountainInsurance: folders.api + "/user/mountainInsurance/pensionInformation",
      updatePhoneCodeMountainInsurance: folders.api + "/user/mountainInsurance/updatePhoneCode",
    },
    client: {
      getClientsList: folders.api + "/clients?:current&:pageSize",
      getTreatmentClientsList: folders.api + "/treatment/clients?:current&:pageSize",
      getClient: folders.api + "/client?:clientId",
      getClientWithoutSignature: folders.api + "/client/withoutSignature?:clientId",
      sendAgainDocumentSignature: folders.api + '/client/sendAgain/documents/signature',
      sendPoliciesToInsuranceCompanies: folders.api + '/client/sendToInsuranceCompanies/policies',
      getClientDetails: folders.api + "/client/details?:clientId",
      getClientDetailsProducts: folders.api + "/client/details/products?:clientId",
      getClientDetailsProductsElementary: folders.api + "/client/details/productsElementary?:clientId",
      getPolicyDocument: folders.api + "/client/details/document/policy?:documentId",
      deletePolicyDocument: folders.api + "/client/details/delete/document/policy",
      getClientDetailsDocuments: folders.api + "/client/details/documents?:clientId",
      getClientDetailsInsuranceMountainDocuments: folders.api + "/client/details/InsuranceMountain/document?:clientId",
      getClientDetailsSignatureDocuments: folders.api + "/client/details/signatureDocuments?:clientId",
      getBoxDocuments: folders.api + "/client/details/boxes/documents?:clientId",
      deleteBoxDocuments: folders.api + "/client/details/delete/boxes",
      deleteDocument: folders.api + "/client/deleteDocument?:clientId&:documentId",
      deleteInsuranceMountainDocument: folders.api + "/client/deleteInsuranceMountainDocument?:clientId&:documentId",
      getClientTasks: folders.api + "/tasks/client?:clientId",
      createClientTask: folders.api + "/task/client/create",
      updateClientTask: folders.api + "/task/client/update",
      boxesFileClient: folders.api + "/client/details/boxes",
      getTasks: folders.api + "/tasks?:id&:clientId&:treatmentId&:productId&:policyId&:type&:subtype&:status&:createdAt&:updatedAt&:current&:pageSize",
      importSMS: folders.api + "/clients/importSMS",
      importMislakaSMS: folders.api + "/clients/importMislakaSMS",
      orderMislakaSMS: folders.api + "/clients/orderMislakaSMS",
      create: folders.api + "/clients/create",
      update: folders.api + "/clients/update",
      updateClientDetailsProductsElementaryOwner: folders.api + "/client/details/productsElementary/update/owner",
      file: {
        uploadFile: folders.api + "/clients/uploadFile",
        uploadInsuranceMountainFile: folders.api + "/clients/uploadInsuranceMountainFile",
        uploadPolicyFile: folders.api + "/clients/uploadPolicyFile"
      }
    },
    task: {
      getTaskProperties: folders.api + "/task/properties?:type&:subtype",
      getProperties: folders.api + "/properties",
      getPropertiesCompanyOrDefault: folders.api + "/properties/type?:company",
      getSubProperties: folders.api + "/properties/subtype?:typeId",
      createProperty: folders.api + "/property/create",
      updateProperty: folders.api + "/property/update",
    },
    meeting: {
      get: folders.api + "/meetings?:start&:end&:timezone",
      details: folders.api + "/meeting/details?:id",
      create: folders.api + "/meeting/create",
      update: folders.api + "/meeting/update",
      delete: folders.api + "/meeting/delete",
    },
    conversation: {
      getClients: folders.api + "/conversations/clients?:type",
      getMessages: folders.api + "/conversation/messages?:client_id",
      addMessage: folders.api + "/conversation/create",
      readMessages: folders.api + "/conversation/messages/read",
      sendCode: folders.api + "/conversation/client/sendCode",
      verifyCode: folders.api + "/conversation/client/verifyCode",
    },
    product: {
      getProductsList: folders.api + "/products",
    },
    signature: {
      createClient: folders.api + "/clients/signature/client/create",
      updateClient: folders.api + "/clients/signature/client/update",
      sign: folders.api + "/clients/signature",
    },
    admiringFactor: {
      file: {
        lastFiles: folders.api + "/admiringFactor/files/last",
        getFilesList: folders.api + "/admiringFactor/files",
        uploadFiles: folders.api + "/admiringFactor/files/uploadFiles"
      },
      employers: {
        list: folders.api + "/admiringFactor/employers",
        types: folders.api + "/admiringFactor/employers/types",
        create: folders.api + "/admiringFactor/employers/create",
        update: folders.api + "/admiringFactor/employers/update"
      }
    },
    reports: {
      insuranceMountain: folders.api + "/reports/insuranceMountain"
    }
  }
}
export default baseService;
