import {Button, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import Messages from "../../components/antDesign/Message";
import {createClient, updateClient} from "../../services/client";
import PATTERNS from "../../helpers/Patterns";
import {getLoggedInUser} from "../../services/auth";
import {getUsersByType} from "../../services/user";

const {Text} = Typography;

interface Props {
  visible: boolean;
  isNew: boolean;
  clientDataModal: any;
  onClose: (refresh: boolean) => void;
}

const AddEditClientModal: React.FC<Props> = (props) => {

  const [agents, setAgents] = useState<any>([]);
  const [submitTarget, setSubmitTarget] = useState<string | undefined>(undefined);
  const [signatureBy, setSignatureBy] = useState<string | undefined>(undefined);

  const [form] = Form.useForm();

  const handleCreate = (values: any) => {
    values.hasSendSignature = submitTarget === 'createAndSend';
    createClient({client: values}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('הלקוח נוצר בהצלחה')
        props.onClose(true)
      } else {
        Messages.Error('יצירת הלקוח נכשלה')
      }
    })
  }

  const handleUpdate = (values: any) => {
    updateClient({client: values}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('הלקוח עודכן בהצלחה')
        props.onClose(true)
      } else {
        Messages.Error('עדכון הלקוח נכשלה')
      }
    })
  }

  const getAgentsList = () => {
    getUsersByType({type: 'agent'}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        if (response.users.length > 0) {
          const options = response.users.map((agent: any) => {
            return {
              label: agent.firstName + ' ' + agent.lastName,
              value: agent._id
            }
          })
          setAgents(options);
        } else {
          setAgents([]);
        }
      }
    })
  }

  const resetValues = () => {
    setAgents([]);
    setSignatureBy(undefined)
    form.resetFields();
  }

  const handleAfterClose = () => {
    resetValues();
  }

  const onModalOpen = () => {
    if (props.isNew)
      setSignatureBy('byEmail');

    getAgentsList();
  }

  useEffect(() => {
    if (props.visible) {
      onModalOpen()
    }
    return () => {
    };
  }, [props.visible])

  return (
    <Modal
      title={props.isNew ? "הוספת לקוח" : "עריכת לקוח"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose(false)}
      maskClosable={false}
      destroyOnClose
      footer={props.isNew ? [
        <Button key="signature" type="primary" onClick={() => {
          setSubmitTarget('createAndSend');
          form.submit();
        }}>
          הוסף ושלח לחתימות
        </Button>,
        <Button key="add" onClick={() => {
          setSubmitTarget('create');
          form.submit();
        }}>
          הוסף
        </Button>
      ] : [
        <Button key="update" type="primary" onClick={() => {
          setSubmitTarget('update');
          form.submit();
        }}>
          עדכן
        </Button>
      ]}
      width={1000}>

      <Form form={form}
            onValuesChange={(values) => {
            }}
            initialValues={{
              'appendixDocuments': ['appendixA', 'appendixB', 'appendixE'],
            }}
            layout="vertical"
            onFinish={props.isNew ? handleCreate : handleUpdate}>

        <Form.Item
          name='_id'
          initialValue={props.clientDataModal && props.clientDataModal._id ? props.clientDataModal._id : ''}
          hidden>
          <Input hidden/>
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="שם פרטי"
              initialValue={props.clientDataModal && props.clientDataModal.firstName ? props.clientDataModal.firstName : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='firstName'>
              <Input placeholder="שם פרטי"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="שם משפחה"
              initialValue={props.clientDataModal && props.clientDataModal.lastName ? props.clientDataModal.lastName : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='lastName'>
              <Input placeholder="שם משפחה"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="תעודת זהות"
              initialValue={props.clientDataModal && props.clientDataModal.clientId ? props.clientDataModal.clientId : ''}
              rules={[{required: true, message: "שדה חובה"}, {
                pattern: PATTERNS.NUMBER_ID,
                message: "מס׳ תעודת זהות לא חוקי"
              }]}
              name='clientId'>
              <Input placeholder="תעודת זהות"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="טלפון נייד"
              initialValue={props.clientDataModal && props.clientDataModal.phone ? props.clientDataModal.phone : ''}
              rules={[{required: true, message: "שדה חובה"}, {
                pattern: PATTERNS.MOBILE,
                message: "טלפון לא חוקי"
              }]}
              name='phone'>
              <Input placeholder="טלפון נייד"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="טלפון קווי"
              initialValue={props.clientDataModal && props.clientDataModal.telephone ? props.clientDataModal.telephone : ''}
              name='telephone'>
              <Input placeholder="טלפון קווי"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="אימייל"
              initialValue={props.clientDataModal && props.clientDataModal.email ? props.clientDataModal.email : ''}
              rules={signatureBy && signatureBy === 'byEmail' ?
                [{type: 'email', message: 'אימייל לא תקין'}, {required: true, message: "שדה חובה"}] :
                [{type: 'email', message: 'אימייל לא תקין'}]}
              name='email'>
              <Input placeholder="אימייל"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="תאריך לידה"
              initialValue={props.clientDataModal && props.clientDataModal.dateOfBirth ? props.clientDataModal.dateOfBirth : ''}
              name='dateOfBirth'>
              <DatePicker/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="תאריך הנפקת תעודת זהות"
              initialValue={props.clientDataModal && props.clientDataModal.issueIdDate ? props.clientDataModal.issueIdDate : ''}
              name='issueIdDate'>
              <DatePicker/>
            </Form.Item>
          </Col>

          {
            props.isNew && getLoggedInUser().type === 'treatment' &&
            <Col lg={8} md={12} sm={12} xs={12}>
              <Form.Item
                label="סוכן"
                initialValue={props.clientDataModal && props.clientDataModal.agent?._id ? props.clientDataModal.agent?._id : ''}
                rules={[{required: true, message: "שדה חובה"}]}
                name='agentId'>
                <Select placeholder='בחר'>
                  {
                    agents.map((agent: any) => <Select.Option key={agent.value}
                                                              value={agent.value}>{agent.label}</Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Col>
          }

          {
            !props.isNew && getLoggedInUser().type === 'treatment' &&
            <Col lg={8} md={12} sm={12} xs={12}>
              <Form.Item
                label="סוכן"
                initialValue={props.clientDataModal && props.clientDataModal.agent?._id ? props.clientDataModal.agent?._id : ''}
                name='agentId'>
                <Text>{
                  props.clientDataModal?.agent && props.clientDataModal?.agent.firstName &&
                  props.clientDataModal?.agent.lastName ? (props.clientDataModal?.agent.firstName + ' ' +
                    props.clientDataModal?.agent.lastName) : "--"}</Text>
              </Form.Item>
            </Col>
          }
        </Row>

        <Row gutter={[16, 16]}>
          <Col lg={24} md={24} sm={24} xs={24} className="mt-4 mb-3">
            <Text strong type="warning">כתובת</Text>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="מדינה"
              initialValue={props.clientDataModal && props.clientDataModal.country ? props.clientDataModal.country : ''}
              name='country'>
              <Input placeholder="מדינה"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="עיר"
              initialValue={props.clientDataModal && props.clientDataModal.city ? props.clientDataModal.city : ''}
              name='city'>
              <Input placeholder="עיר"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="רחוב"
              initialValue={props.clientDataModal && props.clientDataModal.street ? props.clientDataModal.street : ''}
              name='street'>
              <Input placeholder="רחוב"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="מספר בית"
              initialValue={props.clientDataModal && props.clientDataModal.houseNumber ? props.clientDataModal.houseNumber : ''}
              name='houseNumber'>
              <Input placeholder="מספר בית"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="מיקוד"
              initialValue={props.clientDataModal && props.clientDataModal.postalCode ? props.clientDataModal.postalCode : ''}
              name='postalCode'>
              <Input placeholder="מיקוד"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="הערות"
              initialValue={props.clientDataModal && props.clientDataModal.note ? props.clientDataModal.note : ''}
              name='note'>
              <Input placeholder="הערות"/>
            </Form.Item>
          </Col>

          {props.isNew &&
            <>
              <Col lg={8} md={12} sm={12} xs={12}>
                <Form.Item name="appendixDocuments" label="מסמכים לתחימה">
                  <Checkbox.Group>
                    <Row>
                      <Col span={24}>
                        <Checkbox value="appendixA" style={{lineHeight: '32px'}}>
                          נספח א׳ (מסלקה)
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox value="appendixB" style={{lineHeight: '32px'}}>
                          נספח ב׳ (חברות ביטוח)
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox value="appendixE" style={{lineHeight: '32px'}}>
                          נספח ה׳ (הר הביטוח)
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>

              <Col lg={4} md={6} sm={12} xs={12} className="pt-2">
                <Form.Item initialValue="byEmail" name="signatureBy" label="חתום באמצעות">
                  <Radio.Group onChange={(event) => setSignatureBy(event.target.value)}>
                    <Radio value="byEmail">Email</Radio>
                    <Radio value="byWhatsapp">Whatsapp</Radio>
                    <Radio value="bySMS">SMS</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </>
          }
        </Row>
      </Form>


    </Modal>
  );
};

export default AddEditClientModal;
