import {Button, Form, Input, Modal, Spin} from 'antd';
import React, {useState} from 'react';
import Messages from "../../../../../components/antDesign/Message";
import {importClientSMS, importMislakaSMS, orderMislakaSMS} from "../../../../../services/client";
import PATTERNS from "../../../../../helpers/Patterns";
import moment from "moment";

interface Props {
  onClose: (clientId?: any | undefined) => void;
}

const ImportSMSModal: React.FC<Props> = ({
                                           onClose
                                         }
) => {
  const [form] = Form.useForm();
  const [isExistingClient, setIsExistingClient] = useState(false);
  const [orderMislaka, setOrderMislaka] = useState(false);
  const [orderMislakaOld, setOrderMislakaOld] = useState(false);
  const [smsClientId, setSmsClientId] = useState(0);
  //const [mislakaFileId, setMislakaFileId] = useState('');
  const [responseData, setResponseData] = useState<{ mislakaDate?: string, fileId?: string } | undefined>(undefined);
  const [clientIdToUpdate, setClientIdToUpdate] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [orderMislakaOldLoading, setOrderMislakaOldLoading] = useState<boolean>(false);
  const [downloadMislakaOldLoading, setDownloadMislakaOldLoading] = useState<boolean>(false);

  const handleImport = (values: { clientId: string; updateDetails: boolean }) => {
    setLoading(true);
    importClientSMS({...values}).then(response => {
      if (response.status === 'SUCCESS') {
        setIsExistingClient(true); // Show confirmation dialog
        onClose(response.clientId);
        Messages.Success('ייבוא הלקוח בוצע בהצלחה').then(() => {
        });
      } else if (response.status === 'EXISTS') {
        setClientIdToUpdate(response.clientId);
        setIsExistingClient(true);
      } else if (response.status === 'MISLAKA_ERROR') {
        setClientIdToUpdate(response.clientId);
        setSmsClientId(response.smsClientId);
        switch (response.reason) {
          case "NOT_FOUND":
            setOrderMislaka(true);
            break;
          case "WAITING":
            Modal.info({
              title: 'מצב מסלקה',
              content: (
                <div>
                  <p>פרטי העמית/ה עודכנו בהצלחה!</p>
                  <p>הזמנת מסלקה לא הושלמה עדיין</p>
                </div>),
              onOk() {
                onClose(response.clientId);
              },
            });
            break;
          case 'OLD_FOUND':
            setOrderMislakaOld(true);
            setResponseData(response.data)
            break;
        }
      } else if (response.status === 'ERROR') {
        switch (response.code) {
          case 404:
            Messages.Error('תעודת זהות אינה קיימת ב SMS').then(() => {
            });
            setLoading(false);
            break;
          default:
            Messages.Error('ייבוא הלקוח נכשל').then(() => {
            });
            setLoading(false);
        }
      } else {
        Messages.Error('ייבוא הלקוח נכשל').then(() => {
        });
        setLoading(false);
      }

    }).catch(err => {
      Messages.Error('שגיאה בזמן ייבוא לקוח').then(() => {
      });
      console.error(err);
      setLoading(false);
    });
  };

  const handleConfirmUpdate = (updateDetails: boolean) => {
    handleImport({clientId: form.getFieldValue('clientId').toString(), updateDetails});
  };

  const handleAfterClose = () => {
    onClose(undefined);
    form.resetFields();
    setIsExistingClient(false); // Reset confirmation dialog
  };

  const handleOrderMislaka = () => {
    orderMislakaSMS({smsClientId}).then((response) => {
      if (response.status === 'MISSING-ERROR') {
        Modal.error({
          title: 'חוסר מידע',
          content: (
            <div>
              <p>על מנת להזמין מסלקה יש להשלים את הפרטים הבאים:</p>
              <ul>
                {response.list.map((data: String) => <li>{data}</li>)}
              </ul>
            </div>),
          onOk() {
            onClose(clientIdToUpdate);
          },
        });
      }

      if (response.status === 'SUCCESS') {
        onClose(clientIdToUpdate);
        Messages.Success('הוזמנה מסלקה בהצלחה!').then(() => {
        });
      }
    });
  }

  return (
    <Modal
      title="ייבוא לקוח מ - SMS"
      afterClose={handleAfterClose}
      open={true}
      onCancel={() => onClose(false)}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button
          key="add"
          loading={loading}
          onClick={() => {
            form.submit();
          }}
        >
          ייבוא
        </Button>
      ]}
    >
      <Spin  spinning={loading}>
        <Form form={form} onFinish={handleImport}>
          <Form.Item
            label="תעודת זהות"
            name="clientId"
            rules={[
              {required: true, message: "שדה חובה"},
              {pattern: PATTERNS.NUMBER_ID, message: "מס׳ תעודת זהות לא חוקי"}
            ]}
          >
            <Input placeholder="תעודת זהות" inputMode="numeric"/>
          </Form.Item>
        </Form>
      </Spin>

      <Modal
        open={isExistingClient}
        title="אישור עדכון פרטי עמית/ה"
        onCancel={() => {
          setLoading(false);
          setIsExistingClient(false)
        }}
        footer={[
          <Button key="no" onClick={() => {
            setIsExistingClient(false);
            handleConfirmUpdate(false);
          }}>
            לא
          </Button>,
          <Button key="yes" type="primary" onClick={() => {
            setIsExistingClient(false);
            handleConfirmUpdate(true);
          }}>
            כן
          </Button>
        ]}
      >
        <p>לקוח זה קיים במערכת, האם לעדכן את פרטי העמית/ה?</p>
      </Modal>

      <Modal
        open={orderMislaka}
        title="הזמנת מסלקה"
        onCancel={() => {
          onClose(clientIdToUpdate);
        }}
        footer={[
          <Button key="no" onClick={() => {
            setOrderMislaka(false);
            onClose(clientIdToUpdate);
          }}>
            לא
          </Button>,
          <Button key="yes" type="primary" onClick={() => {
            setOrderMislaka(false);
            handleOrderMislaka();
          }}>
            כן
          </Button>
        ]}
      >
        <p>פרטי העמית/ה עודכנו בהצלחה!</p>
        <p>לא נמצא קובץ מסלקה במערכת SMS, האם תרצה להזמין קובץ?</p>
      </Modal>


      <Modal
        open={orderMislakaOld}
        title="הזמנת מסלקה"
        onCancel={() => {
          if(!downloadMislakaOldLoading && !orderMislakaOldLoading) {
            setLoading(false);
            setIsExistingClient(false);
            onClose(clientIdToUpdate);
          }
        }}
        footer={[
          <Button key="no" loading={downloadMislakaOldLoading} disabled={orderMislakaOldLoading}
            onClick={() => {
            setOrderMislaka(false);
            setDownloadMislakaOldLoading(true);
            importMislakaSMS({
              smsClientId: smsClientId,
              clientId: form.getFieldValue('clientId').toString(),
              fileId: responseData?.fileId
            }).then(response => {
              if (response.status === 'SUCCESS') {
                setIsExistingClient(true); // Show confirmation dialog
                onClose(clientIdToUpdate);
                Messages.Success('ייבוא הלקוח בוצע בהצלחה').then(() => {
                });
              }
            });
          }}>
            הורד נוכחי
          </Button>,
          <Button key="yes" type="primary" disabled={downloadMislakaOldLoading} loading={orderMislakaOldLoading} onClick={() => {
            setOrderMislaka(false);
            setOrderMislakaOldLoading(true);
            handleOrderMislaka();
          }}>
            הזמן חדש
          </Button>
        ]}
      >
        <p>פרטי העמית/ה עודכנו בהצלחה!</p>
        <p>נמצא קובץ מסלקה מהתאריך {moment(responseData?.mislakaDate).format('DD/MM/YYYY')}, האם ברצונך לייבא אותו או
          להזמין חדש?</p>
      </Modal>

    </Modal>
  );
};

export default ImportSMSModal;
